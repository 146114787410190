import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles, useClsUtils } from '../../styles';
import Typography from '../../mui/material/Typography';
import { imagesCached, imagesError } from '../../lib/lazyload';
import { useBrowser } from '../../context/browser';
import brokenImageIcon from '../../assets/broken-image.svg';
import placeholderImage from '../../assets/placeholder.gif';
import { usePrevious } from '../../hooks';
import { BROWSER } from '../../lib/helpers';

const LOAD_IMAGES = true;

export function handleLoad(event, img) {
	if (!BROWSER) return false;

	window.addEventListener('load', () => {
		const target = event?.target || img;
		const parent = target?.parentElement;
		parent?.classList?.remove(
			'img-error',
			'img-placeholder',
			'img-loading',
			'img-loading-wave',
			'img-loading-pulse',
			'img-loading-spinner'
		);
	}, { once: true });
}

export function handleError(event, img) {
	if (!BROWSER) return false;

	window.addEventListener('load', () => {
		const target = event?.target || img;
		const parent = target?.parentElement;
		handleLoad(event, img);
		parent?.classList?.add(
			'img-error'
		);
	}, { once: true });
}

export function isCached(src) {
	if (!BROWSER) return false;

	const img = new Image();
	img.src = src;
	const complete = img.complete;
	img.src = '';
	return complete;
}

// if (BROWSER) {
// 	document.querySelectorAll('img[loading=lazy]').forEach(img => {
// 		const tester = new Image();
// 		tester.onload = event => handleLoad(event, img);
// 		tester.onerror = event => handleError(event, img);
// 		if (img.complete) {
// 			tester.src = img.src;
// 		}
// 	});
// }

const useStyles = createStyles((theme, {width, height, objectPosition, showPlaceholder, rounded}) => {
	const placeholderMixin = {
		'&:before': {
			top: 0,
			left: 0,
			position: 'absolute',
			width: '100%',
			height: '100%',
			content: '""',
			...(!LOAD_IMAGES && showPlaceholder && {
				backgroundColor: theme.config.palette.background.placeholder
			})
		},
		...(showPlaceholder && {
			'& > img': {
				backgroundColor: theme.config.palette.background.placeholder
			}
		})
	};

	return {
		root: {
			position: 'relative',
			...(width && height && {
				width: `${width}px`,
				height: 'auto',
				aspectRatio: `auto ${width} / ${height}`,
				'@supports not (aspect-ratio: auto)': {
					paddingTop: `${height / width * 100}%`,
					position: 'relative',
					maxWidth: '100%',
					width: '100%',
					height: 0,
					'& > img': {
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0
					}
				}
			}),

			'&.img-placeholder': {
				...placeholderMixin,
				overflow: 'hidden'
			},
			'&.img-error': {
				...placeholderMixin,
				overflow: 'hidden',
				backgroundColor: theme.config.palette.background.placeholder,
				'&:before': {
					...placeholderMixin['&:before'],
					backgroundSize: '40% 40%',
					backgroundPosition: 'center center',
					backgroundRepeat: 'no-repeat',
					backgroundImage: `url(${brokenImageIcon})`
				},
				'& > img': {
					opacity: 0
				}
			},
			'&.img-loading': {
				'& > img': {
					objectFit: 'cover', // Fixes aspect ratio for placeholder
					opacity: 0
				},
				'&.img-loading-pulse': {
					animationDelay: '1s',
					animation: `${theme.helpers.keyframes.pulse} 1.5s ease-in-out 0.5s infinite`
				},
				'&.img-loading-wave': {
					position: 'relative',
					overflow: 'hidden',
					'&::after': {
						animationDelay: '1s',
						animation: `${theme.helpers.keyframes.wave} 1.6s linear 0.5s infinite`,
						background: `linear-gradient(90deg, transparent, ${theme.config.palette.action.hover}, transparent)`,
						content: '""',
						position: 'absolute',
						transform: 'translateX(-100%)',
						bottom: 0,
						left: 0,
						right: 0,
						top: 0
					}
				},
				'&.img-loading-spinner': {
					overflow: 'hidden',
					'&::before': {
						...theme.mixins.spinner['&:before'],
						opacity: 0,
						animationDelay: '1s',
						backgroundSize: '15% 15%'
					}
				}
			},
			'&.img-fadein > img': {
				...theme.mixins.animations.fadeInFast
			}
		},
		fullWidth: {
			maxWidth: '100%',
			width: '100%',
			'& > img': {
				maxWidth: '100%',
				maxHeight: '100%',
				width: '100%',
				height: 'auto'
			}
		},
		fullScreen: {
			height: 'auto',
			'&, & > img': {
				maxWidth: '100%',
				maxHeight: '100%'
			}
		},
		responsive: {
			maxWidth: '100%',
			maxHeight: '100%',
			width: 'fit-content',
			height: 'auto',
			'& > img': {
				maxWidth: '100%',
				maxHeight: '100%'
			}
		},
		common: {
			...(rounded && {
				borderRadius: `${theme.shape.borderRadius}px`
			}),
			transition: theme.transitions.create(['background'], {
				easing: theme.transitions.easing.easeIn,
				duration: theme.transitions.duration.standart
			}),
			'&:not(.img-placeholder) &:not(.img-error)': {
				backgroundColor: 'transparent',
				'&:before': {
					background: 'none'
				}
			}
		},
		img: {
			display: 'block',
			color: 'transparent',
			textIndent: -10000,
			...(objectPosition && {
				objectPosition
			})
		},
		thumbnail: {
			'&.img-placeholder, & > img': {
				...theme.mixins.image.thumbnail
			}
		},
		centered: {
			textAlign: 'center',
			margin: '0 auto'
		},
		caption: {
			position: 'absolute',
			zIndex: 1,
			top: 'auto',
			bottom: 0,
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			color: theme.config.palette.common.white,
			backgroundColor: 'rgba(0, 0, 0, .6)',
			padding: theme.spacing(1)
		}
	};
}, {
	name: 'RaImg',
	mergeClassName: false
});

export function getImageSrc(browser, src = '', srcSet) {
	if (!browser) {
		throw new Error('Browser context missing');
	}

	const {isWidthUp, breakpoint} = browser;

	if (!src && srcSet?.src) {
		return srcSet;
	}

	if (srcSet && typeof srcSet !== 'string' && Object.keys(srcSet).length) {
		for (let [key, val] of Object.entries(srcSet)) {
			if (val && isWidthUp(key, breakpoint, true)) {
				src = val;
			}
		}
		return src || srcSet?.xs;
	}
	return src;
}

export function useImageSrc(src, srcSet) {
	const {isWidthUp, breakpoint} = useBrowser();
	return React.useMemo(() => (
		getImageSrc({isWidthUp, breakpoint}, src, srcSet)
	), [isWidthUp, breakpoint, src, srcSet]);
}

export function ImgSrcSet(props) {
	const {
		src,
		srcSet,
		width: widthProp,
		height: heightProp,
		...rest
	} = props;

	const {
		src: imgSrc,
		width: imgWidth,
		height: imgHeight
	} = useImageSrc(src, srcSet);

	const width = widthProp || imgWidth;
	const height = heightProp || imgHeight;

	return (
		<Img
			{...rest}
			src={imgSrc}
			width={width}
			height={height}
		/>
	);
}

function Img(props) {
	const imgRef = React.useRef();
	const observerRef = React.useRef();

	const browser = useBrowser();
	const {
		lazyload,
		lazyImageSupport,
		breakpoint
	} = browser;

	const { cxCls: clsx } = useClsUtils();

	const {
		classes: classesProp,
		className,
		children,
		caption,
		alt,
		src: srcProp,
		srcSet,
		title,
		height,
		width,
		thumbnail,
		placeholderSrc = placeholderImage,
		lazyload: lazyloadProp = true,
		loadSync: loadSyncProp = true,
		centered,
		responsive = true,
		fullWidth,
		fullScreen,
		imgProps = {},
		loading: loadingProp,
		loadingAnimation,
		loadingDelay,
		position,
		fadeIn,
		showPlaceholder,
		objectPosition,
		component: Component = 'div',
		rounded,
		...rest
	} = props;

	const { classes } = useStyles(props);

	const lazyloadNative = (!BROWSER || lazyImageSupport);
	const src = getImageSrc(browser, srcProp, srcSet);
	const imageSrc = src || null;
	const cached = imagesCached.includes(imageSrc);
	const loadSync = LOAD_IMAGES && ((loadSyncProp && !lazyloadProp) || (loadSyncProp && lazyloadProp && cached) || lazyloadNative);
	const loaded = loadSync || (LOAD_IMAGES && cached);

	const [state, setState] = React.useState({
		loading: !loadSync,
		placeholder: showPlaceholder,
		error: !src || imagesError.includes(imageSrc)
	});

	const {loading, placeholder, error} = state;

	const prevbreakpoint = usePrevious(breakpoint);

	function checkImage() {
		const img = imgRef.current;
		if (!img.complete) {
			return false;
		}
		if (img.naturalWidth === 0) {
			return false;
		}
		return true;
	}

	React.useEffect(() => {
		if (lazyloadNative) {
			return;
		}

		let reqId;
		let didCancel = false;
		let observerInstance = observerRef.current;
		const observer = lazyload?.observer;
		const img = imgRef.current;

		const handleLoaded = () => {
			if (!didCancel) {
				setState({
					loading: false,
					placeholder: false,
					error: false
				});
			}
		};

		const handleError = () => {
			if (!didCancel) {
				setState({
					loading: false,
					placeholder: false,
					error: true
				});
			}
		};

		const loadImage = async () => {
			if (lazyloadNative || didCancel || loadSync || !LOAD_IMAGES || !lazyload || !img) return;

			if (!loadSync && lazyloadProp && !lazyload) {
				console.error('Lazyload instance missing!');
			}

			if (loaded && (loading || img.getAttribute('src') !== imageSrc)) {
				img.onerror = () => {
					handleError();
					img.onerror = null;
				};
				img.onload = () => {
					handleLoaded();
					img.onload = null;
				};
				return img.src = imageSrc;
			}

			if (lazyloadProp && observer && !observerInstance && img instanceof HTMLElement) {
				observerInstance = observer.observe(img);
				return;
			}

			const imgLoaded = await lazyload.loadImage(img, imageSrc).catch(handleError);

			if (imgLoaded) {
				if (!imagesCached.includes(imgLoaded.src)) {
					imagesCached.push(imgLoaded.src);
				}
				handleLoaded();
			}
		};

		const updateImages = () => {
			loadImage();

			if (lazyloadProp && img && checkImage()) {
				if (error) {
					handleLoaded();
				}
			}
		};

		if (!didCancel && !loadSync) {
			if (prevbreakpoint && prevbreakpoint !== breakpoint) {
				reqId = window.requestAnimationFrame(updateImages);
			} else {
				reqId = window.requestAnimationFrame(loadImage);
			}
		}

		return () => {
			didCancel = true;
			window.cancelAnimationFrame(reqId);
			if (observer && observerInstance && img instanceof HTMLElement) {
				observer.unobserve(img);
			}
		};
	}, [
		lazyload,
		loadSync,
		imageSrc,
		error,
		loaded,
		loading,
		lazyloadProp,
		lazyloadNative,
		prevbreakpoint,
		breakpoint
	]);

	return (
		<Component
			{...rest}
			className={clsx(
				'RaImg-root',
				classes.common,
				classes.root,
				responsive && classes.responsive,
				fullWidth && classes.fullWidth,
				fullScreen && classes.fullScreen,
				centered && classes.centered,
				thumbnail && classes.thumbnail,
				!error && !loadSync && !cached && fadeIn && 'img-fadein',
				!error && !loadSync && !cached && loading && clsx(
					'img-loading',
					loadingAnimation === 'pulse' && 'img-loading-pulse',
					loadingAnimation === 'wave' && 'img-loading-wave',
					loadingAnimation === 'spinner' && 'img-loading-spinner'
				),
				!error && placeholder && 'img-placeholder',
				error && 'img-error',
				className
			)}
		>
			<img
				{...imgProps}
				ref={imgRef}
				className={clsx(
					classes.common,
					classes.img
				)}
				key={imageSrc}
				width={Math.round(width) || null}
				height={Math.round(height) || null}
				alt={alt || imageSrc}
				title={title}
				src={loadSync ? imageSrc : placeholderSrc}
				srcSet={srcSet && typeof srcSet === 'string' ? srcSet : null}
				data-src={!loadSync && !loaded && lazyloadProp ? imageSrc : null}
				data-delay={loadingDelay && lazyloadProp && !loaded ? loadingDelay : null}
				loading={loadingProp ? 'lazy' : null}
				onError={handleError}
				onLoad={handleLoad}
			/>
			{caption ? (
				<div className={classes.caption}>
					<Typography
						fontSize="inherit"
						color="inherit"
						textAlign="center"
						variant="subtitle2"
					>
						{caption}
					</Typography>
				</div>
			) : null}
			{children}
		</Component>
	);
}

Img.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	caption: PropTypes.string,
	alt: PropTypes.string,
	title: PropTypes.string,
	src: PropTypes.string,
	srcSet: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	imgProps: PropTypes.object,
	placeholderSrc: PropTypes.string,
	loading: PropTypes.oneOf(['eager', 'lazy']),
	lazyload: PropTypes.bool,
	width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
	height: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
	thumbnail: PropTypes.bool,
	centered: PropTypes.bool,
	responsive: PropTypes.bool,
	fullWidth: PropTypes.bool,
	fullScreen: PropTypes.bool,
	loadSync: PropTypes.bool,
	fadeIn: PropTypes.bool,
	showPlaceholder: PropTypes.bool,
	loadingAnimation: PropTypes.oneOf(['pulse', 'wave', 'spinner', false]),
	loadingDelay: PropTypes.number,
	objectPosition: PropTypes.string,
	rounded: PropTypes.bool
};

export default React.memo(Img);
