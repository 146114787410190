import * as React from 'react';
import PropTypes from 'prop-types';
import NoSsr from '../../mui/material/NoSsr';
import { useBrowser } from '../../context/browser';
import { useTheme } from '../../styles';
import { breakpoints } from '../../styles/theme';

function Hidden(props) {
	const {
		children,
		only,
		noSsr,
		...rest
	} = props;

	const theme = useTheme();
	const browser = useBrowser();
	const { isWidthUp, isWidthDown } = browser;

	if (noSsr && !browser?.clientWidth) {
		return (
			<NoSsr>
				{children}
			</NoSsr>
		);
	}

	let visible = true;

	if (only) {
		if (Array.isArray(only)) {
			for (let i = 0; i < only.length; i += 1) {
				const breakpoint = only[i];
				if (browser.breakpoint === breakpoint) {
					visible = false;
					break;
				}
			}
		} else if (only && browser.breakpoint === only) {
			visible = false;
		}
	}

	if (visible) {
		for (let i = 0; i < theme.breakpoints.keys.length; i += 1) {
			const breakpoint = theme.breakpoints.keys[i];
			const breakpointUp = props[`${breakpoint}Up`];
			const breakpointDown = props[`${breakpoint}Down`];
			if (
				(breakpointUp && isWidthUp(breakpoint, browser.breakpoint)) ||
				(breakpointDown && isWidthDown(breakpoint, browser.breakpoint))
			) {
				visible = false;
				break;
			}
		}
	}

	if (!visible) {
		return null;
	}

	return children;
}

Hidden.propTypes = {
	children: PropTypes.node,
	only: PropTypes.oneOfType([
		PropTypes.oneOf(breakpoints.keys),
		PropTypes.arrayOf(PropTypes.oneOf(breakpoints.keys))
	]),
	xlDown: PropTypes.bool,
	xlUp: PropTypes.bool,
	desktopDown: PropTypes.bool,
	desktopUp: PropTypes.bool,
	lgDown: PropTypes.bool,
	lgUp: PropTypes.bool,
	laptopDown: PropTypes.bool,
	laptopUp: PropTypes.bool,
	mdDown: PropTypes.bool,
	mdUp: PropTypes.bool,
	tabletDown: PropTypes.bool,
	tabletUp: PropTypes.bool,
	smDown: PropTypes.bool,
	smUp: PropTypes.bool,
	phoneDown: PropTypes.bool,
	phoneUp: PropTypes.bool,
	xsDown: PropTypes.bool,
	xsUp: PropTypes.bool,
	noSsr: PropTypes.bool
};

export default React.memo(Hidden);
