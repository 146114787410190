import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '../../styles';
import Typography from '../../mui/material/Typography';
import Toolbar from '../../mui/material/Toolbar';
import AppBar from '../AppBar';

const useStyles = createStyles((theme) => ({
	root: {},
	toolbar: {
		width: '100%',
		alignSelf: 'center',
		justifyContent: 'space-between'
	},
	brand: {
		display: 'flex',
		justifyContent: 'flex-start',
		[theme.breakpoints.up('md')]: {
			flexBasis: '20%'
		}
	},
	title: {
		display: 'flex',
		lineHeight: '1.375rem',
		[theme.breakpoints.down('laptop')]: {
			fontSize: '1.125rem'
		},
		[theme.breakpoints.down('phone')]: {
			fontSize: '1rem'
		}
	},
	content: {
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.up('md')]: {
			flexBasis: '20%'
		}
	}
}), {
	name: 'RaFullscreenDialogAppBar'
});

function FullscreenDialogAppBar(props) {
	const {
		classes: classesProp,
		className,
		children,
		title,
		brand,
		ToolbarProps,
		...rest
	} = props;

	const { classes } = useStyles(props);

	return (
		<AppBar
			{...rest}
			toolbarContent={(
				<Toolbar className={classes.toolbar} {...ToolbarProps}>
					{brand && (
						<div className={classes.brand}>
							{brand}
						</div>
					)}
					{title && (
						<Typography
							variant="h6"
							component="h2"
							color="inherit"
							textAlign={brand ? 'center' : 'left'}
							className={classes.title}
						>
							{title}
						</Typography>
					)}
					{children && (
						<div className={classes.content}>
							{children}
						</div>
					)}
				</Toolbar>
			)}
			className={classes.root}
		/>
	);
}

FullscreenDialogAppBar.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	title: PropTypes.string,
	brand: PropTypes.node,
	ToolbarProps: PropTypes.object
};

export default React.memo(FullscreenDialogAppBar);
