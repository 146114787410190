import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '../../mui/material/Stack';
import { createStyles } from '../../styles';

const defaultProps = {
	scrollable: false
};

const useStyles = createStyles((theme, {gutterTop = true, scrollable = defaultProps.scrollable}, sx) => ({
	root: {
		...sx(theme.mixins.gutters()),
		...(!gutterTop && {
			'&&': {
				paddingTop: 0
			}
		}),
		...(scrollable && {
			...theme.mixins.scrollbar,
			height: 'auto',
			overflowY: 'auto',
			outline: 'none',
			'@media not all and (min-resolution:.001dpcm)': {
				'@supports (-webkit-appearance:none) and (not (stroke-color:transparent))': {
					'&&': {
						overflowY: 'visible',
						height: 'auto !important',
						maxHeight: 'none !important'
					}
				}
			}
		})
	}
}), {
	name: 'RaCardContent'
});

function CardContent(props) {
	const {
		classes: classesProp,
		className,
		children,
		scrollable = defaultProps.scrollable,
		gutterTop,
		...rest
	} = props;

	const { classes } = useStyles(props);

	return (
		<Stack
			width="100%"
			{...rest}
			className={classes.root}
		>
			{children}
		</Stack>
	);
}

CardContent.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	scrollable: PropTypes.bool
};

export default React.memo(CardContent);
