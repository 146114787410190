import { useState, useEffect } from 'react';

export default function useDetectColorScheme(defaultScheme = 'light') {
	const [preferredColorScheme, setPreferredColorScheme] = useState(defaultScheme);

	useEffect(() => {
		if (!window.matchMedia) {
			setPreferredColorScheme('light');
			return;
		}

		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

		setPreferredColorScheme(mediaQuery.matches ? 'dark' : 'light');

		function onChange(event) {
			setPreferredColorScheme(event.matches ? 'dark' : 'light');
		}

		mediaQuery.addEventListener('change', onChange);

		return () => {
			mediaQuery.removeEventListener('change', onChange);
		};
	}, []);

	return preferredColorScheme;
}
