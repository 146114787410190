import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '../../styles';
import Snackbar from '../../mui/material/Snackbar';
import Loading from '../Loading';
import SnackbarContent from '../SnackbarContent';
import { useDelay } from '../../hooks';

const loadingSizes = {
	xs: 20
};

const useStyles = createStyles((theme) => ({
	root: {
		[theme.breakpoints.up('xs')]: {
			left: '50%',
			right: 'auto',
			transform: 'translateX(-50%)'
		}
	},
	content: {
		whiteSpace: 'nowrap',
		flexWrap: 'nowrap',
		justifyContent: 'center',
		boxShadow: theme.shadows[6],
		fontSize: theme.typography.pxToRem(14),
		fontWeight: theme.typography.fontWeightMedium,
		flexGrow: 0,
		'&&': {
			minWidth: 'auto'
		}
	},
	loading: {
		marginLeft: theme.spacing(-.25),
		marginRight: theme.spacing(1),
		'&:before': {
			backgroundImage: `url(${theme.config.spinnerSmall})`
		}
	}
}), {
	name: 'RaLoadingSnackbar'
});

function LoadingSnackbar(props) {
	const {
		classes: classesProp,
		className,
		open: openProp = false,
		children = 'Bitte warten, Daten werden geladen',
		delay = 0,
		LoadingProps,
		...rest
	} = props;

	const { classes } = useStyles(props);

	const open = useDelay(delay, openProp);

	return (
		<Snackbar
			{...rest}
			open={open}
			className={classes.root}
		>
			<SnackbarContent className={classes.content}>
				<Loading
					{...LoadingProps}
					grow={false}
					size="xs"
					sizes={loadingSizes}
					className={classes.loading}
				/>
				{children}
			</SnackbarContent>
		</Snackbar>
	);
}

LoadingSnackbar.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	open: PropTypes.bool,
	children: PropTypes.node,
	delay: PropTypes.number,
	LoadingProps: PropTypes.object
};

export default React.memo(LoadingSnackbar);
