import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles, clsx } from '../../styles';
import Img from '../Img';
import CardMedia from '../CardMedia';
import ScrollableViews from '../ScrollableViews';

const useStyles = createStyles((theme) => ({
	root: {},
	carousel: {},
	image: {
		display: 'flex'
	}
}), {
	name: 'RaCardMediaCarousel'
});

const NavButtonProps = {
	hideOnTouchDevices: false
};

function CardMediaCarousel(props) {
	const {
		classes: classesProp,
		className,
		children,
		ImgClasses,
		ImgClassName,
		CarouselProps,
		src,
		...rest
	} = props;

	const { classes } = useStyles(props);

	const ImgProps = {
		...rest,
		fullWidth: false,
		className: clsx(classes.image, ImgClassName),
		classes: ImgClasses
	};

	const img = Array.isArray(src) ? (
		<ScrollableViews
			fullWidth
			NavButtonProps={NavButtonProps}
			{...CarouselProps}
			className={classes.carousel}
		>
			{src.map((val, key) => (
				<Img {...ImgProps} key={key} src={val} />
			))}
		</ScrollableViews>
	) : (
		<Img {...ImgProps} src={src} />
	);

	return (
		<CardMedia
			{...rest}
			img={img}
			className={classes.root}
		>
			{children}
		</CardMedia>
	);
}

CardMediaCarousel.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	ImgClasses: PropTypes.object,
	CarouselProps: PropTypes.object
};

export default React.memo(CardMediaCarousel);
