import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles, useColorMode } from '../../styles';
import Cookies from 'js-cookie';
import Typography from '../../mui/material/Typography';
import Divider from '../../mui/material/Divider';
import ChromeIcon from '../../icons/Chrome';
import FirefoxIcon from '../../icons/Firefox';
import SafariIcon from '../../icons/Safari';
import OperaIcon from '../../icons/Opera';
import InternetExplorerIcon from '../../icons/InternetExplorer';
import { useDidMount } from '../../hooks';
import Dialog from '../Dialog';
import Notification from '../Notification';
import Button from '../Button';
import BaseLink from '../Link';
import { useApp } from '../../context/app';
import { BROWSER, LocalStorage } from '../../lib/helpers';

const useStyles = createStyles((theme) => ({
	root: {
		maxWidth: 500,
		'& > .MuiSnackbarContent-root': {
			margin: 'auto',
			'& > .MuiSnackbarContent-action': {
				margin: theme.spacing(1, 'auto', 0),
				gap: theme.spacing(1),
				flexWrap: 'wrap',
				justifyContent: 'center',
				padding: 0
			}
		}
	},
	text: {
		fontSize: theme.typography.pxToRem(12),
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(2)
		}
	},
	link: {
		whiteSpace: 'nowrap'
	},
	textLink: {},
	iconLink: {
		margin: theme.spacing(0, 1)
	},
	browserIcons: {
		marginTop: theme.spacing(2),
		display: 'flex',
		justifyContent: 'center'
	},
	divider: {
		margin: theme.spacing(2, 0)
	},
	dialog: {},
	button: {
		fontSize: theme.typography.pxToRem(12),
		margin: 0
	}
}), {
	name: 'RaCookieConsent'
});

const Text = ({children, ...rest}) => (
	<Typography variant="body1" color="inherit" {...rest}>
		{children}
	</Typography>
);

const DialogContent = ({children, classes, linkColor, LinkComponent: Link, onClose}) => (
	children || (
		<>
			<Text gutterBottom>
				Wir verwenden Cookies auf unserer Website um die Nutzererfahrung zu verbessern und um den Benutzern bestimmte Dienste und Funktionen bereitstellen zu können.
				Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), beispielsweise für personalisierte Anzeigen und Inhalte oder für Anzeigen- und Inhaltsmessungen.
				Es werden keine der so gesammelten Daten genutzt, um Sie zu identifizieren oder zu kontaktieren.
			</Text>

			<Text gutterBottom>
				Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer{' '}
				<Link color={linkColor} className={classes.textLink} onClick={onClose} href="/datenschutz">
					Datenschutzerklärung
				</Link>.
			</Text>

			<Text>
				Besuchen Sie die Internetseite des{' '}
				<Link color={linkColor} className={classes.textLink} href="http://www.bfdi.bund.de/DE/Home/home_node.html">
					Bundesbeauftragten für den Datenschutz und die Informationsfreiheit
				</Link>{' '} um mehr über Cookies und lokalen Speicher zu erfahren.
			</Text>

			<Divider className={classes.divider}/>

			<Text gutterBottom>
				Klicken Sie auf das Icon Ihres Browsers und folgen Sie den Anleitungen, um Cookies im gesamten Browser zu deaktivieren oder zu verwalten:
			</Text>

			<div className={classes.browserIcons}>
				<Link
					color={linkColor}
					className={classes.iconLink}
					href="https://support.google.com/accounts/answer/61416?hl=de"
					title="Chrome"
				>
					<ChromeIcon fontSize="large"/>
				</Link>
				<Link
					color={linkColor}
					className={classes.iconLink}
					href="https://support.mozilla.org/de-DE/kb/enable-and-disable-cookies-website-preferences"
					title="Firefox"
				>
					<FirefoxIcon fontSize="large"/>
				</Link>
				<Link
					color={linkColor}
					className={classes.iconLink}
					href="https://support.google.com/accounts/answer/61416?hl=de"
					title="Safari"
				>
					<SafariIcon fontSize="large"/>
				</Link>
				<Link
					color={linkColor}
					className={classes.iconLink}
					href="https://help.opera.com/de/latest/web-preferences/#cookies"
					title="Opera"
				>
					<OperaIcon fontSize="large"/>
				</Link>
				<Link
					color={linkColor}
					className={classes.iconLink}
					href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
					title="Internet Explorer"
				>
					<InternetExplorerIcon fontSize="large"/>
				</Link>
			</div>
		</>
	)
);

const anchorOrigin = {
	horizontal: 'right'
};

const DEFAULT_COOKIES_NAME = 'CookieConsent';
const ESSENTIAL_COOKIES_NAME = 'essentialCookiesOnly';

export {
	Text as CookieConsentText,
	DialogContent as CookieConsentDialogContent
};

export function resetCookieConsent(name = DEFAULT_COOKIES_NAME) {
	if (BROWSER) {
		Cookies.remove(name);
		LocalStorage.del(ESSENTIAL_COOKIES_NAME);
	}
}

function CookieConsent(props) {
	const {
		classes: classesProp,
		className,
		cookieName = DEFAULT_COOKIES_NAME,
		debug,
		expires = 365,
		cookieValue = true,
		extraCookieOptions,
		defaultOpen = null,
		onClose,
		onAccept,
		onDecline,
		delay = 1000,
		LinkComponent: Link = BaseLink,
		linkColor = 'primary',
		dialogLinkColor = 'primary',
		description,
		disableDialog = false,
		dialogContent,
		dialogTitle = 'Cookies auf unserer Internetseite',
		btnPrivacyInfoLink = '/datenschutz',
		btnPrivacyInfoTitle = 'Datenschutzerklärung',
		btnMoreInfoTitle = 'mehr erfahren',
		btnAcceptTitle = 'Alle Cookies akzeptieren',
		btnDeclineTitle = 'Nur essenzielle Cookies akzeptieren',
		...rest
	} = props;

	const { classes } = useStyles(props);

	const [{ cookieConsent, darkMode }, {setCookieConsent}] = useApp();
	const [dialogOpen, setDialogOpen] = React.useState(false);

	const theme = useColorMode(darkMode ? 'light' : 'dark');

	const open = typeof defaultOpen === 'boolean'
		? defaultOpen
		: typeof cookieConsent === 'boolean'
			? !cookieConsent
			: false;

	function handleOpen() {
		setCookieConsent(false);
	}

	function handleDecline() {
		LocalStorage.set(ESSENTIAL_COOKIES_NAME, true);
		handleClose();
		if (onDecline) {
			onDecline();
		}
	}

	function handleAccept() {
		LocalStorage.del(ESSENTIAL_COOKIES_NAME);
		handleClose();
		if (onAccept) {
			onAccept();
		}
	}

	function handleClose() {
		setCookieConsent(true);
		Cookies.set(cookieName, cookieValue, { expires, ...extraCookieOptions });
		if (onClose) {
			onClose();
		}
	}

	function handleOpenDialog(event) {
		event.preventDefault();
		setDialogOpen(true);
	}

	function handleCloseDialog() {
		setDialogOpen(false);
	}

	useDidMount(() => {
		if (!open && (Cookies.get(cookieName) === undefined || debug)) {
			const timer = setTimeout(() => {
				handleOpen();
			}, delay);
			return () => clearTimeout(timer);
		}
	});

	return (
		<>
			<Notification
				{...rest}
				className={classes.root}
				nowrap={false}
				open={open}
				defaultOpen={defaultOpen || debug}
				anchorOrigin={anchorOrigin}
				message={
					<Text variant="subtitle2" component="p" className={classes.text}>
						{description || (
							<>
								Wir verwenden Cookies um Ihnen einen besseren Service bieten zu können.
								Einige von ihnen sind essenziell um die Funktion der Website zu gewähren,
								während andere uns helfen, diese Website sowie Ihre Erfahrung zu verbessern.<br/>
							</>
						)}
						{!disableDialog && (
							<>
								<Link color={theme.palette[linkColor].main} className={classes.link} href="#" onClick={handleOpenDialog}>
									{btnMoreInfoTitle}
								</Link>
								{' '} | {' '}
							</>
						)}
						<Link color={theme.palette[linkColor].main} className={classes.link} href={btnPrivacyInfoLink}>
							{btnPrivacyInfoTitle}
						</Link>
					</Text>
				}
				action={[
					<Button
						sx={{color: `${theme.palette[linkColor].main}`}}
						color="inherit"
						size="small"
						key="accept"
						className={classes.button}
						onClick={handleAccept}
					>
						{btnAcceptTitle}
					</Button>,

					<Button
						color="inherit"
						size="small"
						key="decline"
						className={classes.button}
						onClick={handleDecline}
					>
						{btnDeclineTitle}
					</Button>
				]}
			/>

			{!disableDialog && (
				<Dialog
					title={dialogTitle}
					open={dialogOpen}
					className={classes.dialog}
					onClose={handleCloseDialog}
				>
					<DialogContent
						onClose={handleCloseDialog}
						linkColor={dialogLinkColor || linkColor}
						LinkComponent={Link}
						classes={classes}
					>
						{dialogContent}
					</DialogContent>
				</Dialog>
			)}
		</>
	);
}

CookieConsent.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	delay: PropTypes.number,
	onClose: PropTypes.func,
	onAccept: PropTypes.func,
	onDecline: PropTypes.func
};

export default React.memo(CookieConsent);
