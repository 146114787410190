import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '../../styles';
import Typography from '../../mui/material/Typography';

const useStyles = createStyles((theme, {gutterTop, gutterBottom}) => ({
	root: {
		color: 'inherit',
		fontSize: 'inherit',

		...(!gutterTop && {
			'& > :first-child': {
				marginTop: 0
			}
		}),

		...(!gutterBottom && {
			'& > :last-child': {
				marginBottom: 0
			}
		}),

		'& a:not(.MuiButton-root)': {
			...theme.mixins.link,
			fontWeight: 'inherit',
			fontStyle: 'inherit'
		}
	}
}), {
	name: 'RaHtmlParser'
});

function HtmlParser(props) {
	const {
		classes: classesProp,
		className,
		children,
		html,
		component: Component = Typography,
		gutterTop,
		gutterBottom,
		...rest
	} = props;

	const { classes } = useStyles(props);

	return (
		<>
			{html && (
				<Component
					component="div"
					className={classes.root}
					{...rest}
					dangerouslySetInnerHTML={{__html: html}}
				/>
			)}
			{children && (
				<Component
					component="div"
					className={classes.root}
					{...rest}
				>
					{children}
				</Component>
			)}
		</>
	);
}

HtmlParser.propTypes = {
	html: PropTypes.string,
	children: PropTypes.node,
	gutterTop: PropTypes.bool,
	gutterBottom: PropTypes.bool
};

export default React.memo(HtmlParser);
