import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '../../styles';
import ErrorIcon from '../../mui/icons-material/Error';
import MessageBox from '../MessageBox';
import Button from '../Button';

const useStyles = createStyles((theme) => ({
	root: {
		backgroundColor: theme.config.palette.error.dark,
		borderColor: theme.config.palette.error.light,
		color: theme.palette.getContrastText(theme.palette.error.dark),
		margin: theme.spacing(3, 1.5)
	},
	title: {},
	content: {},
	action: {
		paddingTop: theme.spacing(.5),
		paddingBottom: theme.spacing(1.5)
	},
	botton: {}
}), {
	name: 'RaErrorBox'
});

function ErrorBox(props) {
	const {
		classes: classesProp,
		className,
		children,
		showButton = true,
		onClick,
		buttonText = 'Seite neu laden',
		ButtonProps,
		href,
		...rest
	} = props;

	const { classes } = useStyles(props);

	function handleClick(event) {
		window.location.reload();
	}

	return (
		<MessageBox
			icon={<ErrorIcon/>}
			type="error"
			{...rest}
			className={classes.root}
			classes={{
				title: classes.title,
				content: classes.content,
				action: classes.action
			}}
			action={showButton ? (
				<Button
					size="small"
					color="inherit"
					variant="outlined"
					className={classes.botton}
					onClick={href ? null : onClick ? onClick : handleClick}
					href={href}
					{...ButtonProps}
				>
					{buttonText}
				</Button>
			) : null}
		>
			{children}
		</MessageBox>
	);
}

ErrorBox.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	showButton: PropTypes.bool,
	buttonText: PropTypes.string,
	buttonLink: PropTypes.string,
	ButtonProps: PropTypes.object,
	onClick: PropTypes.func
};

export default React.memo(ErrorBox);
