import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '../../mui/material/Paper';
import Stack from '../../mui/material/Stack';
import { useThemeProps } from '@mui/material/styles';

function Card(props) {
	const {
		children,
		...rest
	} = useThemeProps({ props, name: 'RaCard' });

	return (
		<Stack
			position="relative"
			overflow="hidden"
			width="100%"
			height="auto"
			alignItems="center"
			component={Paper}
			{...rest}
		>
			{children}
		</Stack>
	);
}

Card.propTypes = {
	children: PropTypes.node.isRequired
};

export default React.memo(Card);
