import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '../../styles';
import Typography from '../../mui/material/Typography';

const useStyles = createStyles((theme) => ({
	root: {
		position: 'fixed',
		padding: theme.spacing(2, 1),
		bottom: 0,
		zIndex: 100000,
		width: '100%',
		justifyContent: 'center',
		backgroundColor: theme.config.palette.error.main,
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(3, 2)
		}
	},
	text: {
		textAlign: 'center',
		fontSize: '1.125rem',
		fontWeight: 500,
		color: theme.config.palette.common.white
	}
}), {
	name: 'RaNoScriptHint'
});

function NoScriptHint(props) {
	const {
		classes: classesProp,
		className,
		...rest
	} = props;

	const { classes } = useStyles(props);

	return (
		<noscript>
			<div className={classes.root}>
				<Typography className={classes.text}>
					Bitte aktivieren Sie Javascript um diese Webseite benutzen zu können!
				</Typography>
			</div>
		</noscript>
	);
}

NoScriptHint.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string
};

export default React.memo(NoScriptHint);
