import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles, clsx } from '../../styles';
import Divider from '../../mui/material/Divider';
import MuiCardActions from '../../mui/material/CardActions';

const useStyles = createStyles((theme, {dense}) => ({
	root: {
		display: 'flex',
		width: '100%',
		minHeight: theme.spacing(dense ? 5.5 : 6.5),
		padding: theme.spacing(dense ? .75 : 1.25, dense ? .75 : 1),
		'& > .RaCardActions-buttons + .RaCardActions-icons': {
			marginLeft: 0
		}
	},
	divider: {
		width: '100%'
	},
	buttons: {
		display: 'flex',
		'& > :not(:first-of-type)': {
			marginLeft: theme.spacing(1)
		}
	},
	icons: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'flex-end',
		marginRight: 0
	}
}), {
	name: 'RaCardActions'
});

function CardActions(props) {
	const {
		classes: classesProp,
		className,
		children,
		divider,
		DividerProps,
		buttons,
		icons,
		dense,
		...rest
	} = props;

	const { classes } = useStyles(props);

	return (
		<>
			{divider &&
				<Divider
					{...DividerProps}
					className={classes.divider}
				/>
			}
			<MuiCardActions
				className={classes.root}
				{...rest}
			>
				{buttons && (
					<div className={clsx(classes.buttons, 'RaCardActions-buttons')}>
						{buttons}
					</div>
				)}
				{icons && (
					<div className={clsx(classes.icons, 'RaCardActions-icons')}>
						{icons}
					</div>
				)}
				{children}
			</MuiCardActions>
		</>
	);
}

CardActions.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	buttons: PropTypes.node,
	icons: PropTypes.node,
	divider: PropTypes.bool,
	dense: PropTypes.bool,
	DividerProps: PropTypes.object
};

export default React.memo(CardActions);
