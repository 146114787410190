import 'lib/nonce';
import 'react-app/assets/fonts/hind-siliguri';
import { cacheOptions } from 'styles';
import renderer from 'react-app/webpack/client';
import smoothscroll from 'smoothscroll-polyfill';
import { loadableReady } from '@loadable/component';
import routes from 'routes';

smoothscroll.polyfill();

loadableReady(renderer({
	routes,
	cacheOptions
}));
