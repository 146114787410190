import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '../../mui/material/Stack';
import { createStyles } from '../../styles';

const useStyles = createStyles((theme, {dense}, sx) => ({
	root: {
		minHeight: theme.spacing(dense ? 6 : 8),
		...sx({
			...theme.mixins.gutters('paddingX'),
			paddingY: theme.spacing(dense ? .75 : 1.5)
		})
	}
}), {
	name: 'RaCardHeader'
});

function CardHeader(props) {
	const {
		classes: classesProp,
		className,
		children,
		dense,
		...rest
	} = props;

	const { classes } = useStyles(props);

	return (
		<Stack
			width="100%"
			alignItems="center"
			direction="row"
			color="text.primary"
			{...rest}
			className={classes.root}
		>
			{children}
		</Stack>
	);
}

CardHeader.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	dense: PropTypes.bool
};

export default React.memo(CardHeader);

