import * as React from 'react';
import PropTypes from 'prop-types';
import MuiNoSsr from '../../mui/material/NoSsr';

function NoSsr(props) {
	const { disabled, children, ...rest } = props;
	return disabled ? children : (
		<MuiNoSsr {...rest}>
			{children}
		</MuiNoSsr>
	);
}

NoSsr.propTypes = {
	disabled: PropTypes.bool,
	children: PropTypes.node
};

export default React.memo(NoSsr);
