import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';
import CardContent from 'react-app/components/CardContent';
import Parser from 'react-app/components/HtmlParser';

const useClasses = createClasses((theme) => {
	const subheading = {
		lineHeight: 1.4,
		fontSize: theme.typography.pxToRem(16),
		fontWeight: theme.typography.fontWeightBold,
		color: theme.config.palette.text.primary
	};

	return {
		root: {
			flexGrow: 1,
			...theme.mixins.gutters()
		},
		subheading: {
			...subheading
		},
		content: {
			color: theme.config.palette.text.primary,
			fontSize: theme.typography.pxToRem(14),
			[theme.breakpoints.up('md')]: {
				fontSize: theme.typography.pxToRem(16),
			},
			'& p': {
				lineHeight: 1.5
			},
			'& h3': {
				...subheading
			},
			'& > *:first-child': {
				'&, & > li > p': {
					marginTop: 0
				}
			},
			'& > *:last-child': {
				marginBottom: 0
			},
			'& ul, & ol': {
				paddingLeft: theme.spacing(2.5),
				marginBottom: theme.spacing(3),
				'& > li': {
					lineHeight: 1.5,
					marginBottom: theme.spacing(1)
				}
			},
			'& a': {
				color: theme.config.palette.text.link
			}
		}
	};
}, {
	name: 'NewsContent'
});

function NewsContent(props) {
	const {
		className,
		children,
		subtitle,
		contentHtml: html,
		...rest
	} = props;

	const classes = useClasses(props);

	return (subtitle || html || children) ? (
		<CardContent
			{...rest}
			className={classes.root}
		>
			<Parser
				className={classes.content}
				html={html}
			>
				{children}
			</Parser>
		</CardContent>
	) : null;
}

NewsContent.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	subtitle: PropTypes.string,
	contentHtml: PropTypes.string
};

export default React.memo(NewsContent);
