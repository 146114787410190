import * as React from 'react';
import PropTypes from 'prop-types';
import MuiLink from '../../mui/material/Link';

export const colorTransformations = {
	default: 'default.main'
};

export const transformColor = (color = 'inherit') => {
	return colorTransformations[color] || color;
};

const Link = React.forwardRef(function Link(props, ref) {
	const {
		href: hrefProp,
		target: targetProp,
		to,
		children,
		component: Component,
		noLinkStyle = true,
		...rest
	} = props;

	const href = to || hrefProp;

	const isExternalLink = targetProp === '_blank' || (typeof href === 'string' && !href.startsWith('/'));

	let target = targetProp || '_blank';
	if (href && (href.startsWith('mailto:') || href.startsWith('tel:'))) {
		target = '_self';
	}

	const linkProps = {
		ref,
		href,
		rel: isExternalLink && target === '_blank' ? "noopener noreferrer" : undefined,
		target: isExternalLink ? target : targetProp
	};

	if (noLinkStyle) {
		return (
			Component ? (
				<Component
					{...rest}
					{...linkProps}
				>
					{children}
				</Component>
			) : (
				<a
					{...rest}
					{...linkProps}
				>
					{children}
				</a>
			)
		);
	}

	return (
		<MuiLink
			{...rest}
			{...linkProps}
			color={transformColor(props.color)}
			component={Component}
		>
			{children}
		</MuiLink>
	);
});

Link.propTypes = {
	to: PropTypes.string,
	href: PropTypes.string,
	target: PropTypes.string,
	children: PropTypes.node,
	noLinkStyle: PropTypes.bool,
	component: PropTypes.elementType
};

export default React.memo(Link);
