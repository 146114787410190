import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles, clsx } from '../../styles';
import TextField from '../../mui/material/TextField';
import { useMemoCallback } from 'react-app/hooks';

const useStyles = createStyles((theme) => ({
	root: {
		position: 'relative',
		'& .MuiFormLabel-root': {
			display: 'flex'
		},
		'& .MuiFormHelperText-root': {
			marginLeft: 0,
			marginRight: 0
		}
	},
	charCount: {
		marginLeft: theme.spacing(2),
		order: 2
	}
}), {
	name: 'RaFinalFormTextField'
});

function FinalFormTextField(props) {
	const {
		classes: classesProp,
		className,
		meta,
		input: {
			name,
			onChange,
			value,
			...restInput
		},
		InputProps: {
			onChange: onChangeProp,
			...restInputProps
		} = {},
		TextFieldComponent = TextField,
		helperText: helperTextProp,
		helperTextError,
		label: labelProp,
		required,
		...rest
	} = props;

	const { classes } = useStyles(props);

	const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

	const { maxLength } = props.inputProps || {};
	const labelText = `${labelProp}${required ? ' *' : ''}`;
	const label = Number.isFinite(maxLength) && value ? (
		<>
			{labelText}
			<span className={clsx(classes.charCount, 'RaFinalFormTextField-charCount')}>
				{`${value.length}/${maxLength}`}
			</span>
		</>
	) : labelText;

	const handleChange = useMemoCallback((event) => {
		if (typeof onChangeProp === 'function') {
			onChangeProp(event);
		}
		return onChange(event);
	});

	return (
		<TextFieldComponent
			{...rest}
			className={classes.root}
			helperText={showError ? helperTextError ?? meta.error ?? meta.submitError : helperTextProp}
			label={label}
			name={name}
			onChange={handleChange}
			value={value}
			error={showError}
			InputProps={{
				...restInputProps,
				...restInput,
				inputProps: {
					...restInputProps?.inputProps,
					required
				}
			}}
		/>
	);
}

FinalFormTextField.propTypes = {
	meta: PropTypes.object.isRequired,
	input: PropTypes.object.isRequired,
	TextFieldComponent: PropTypes.elementType,
	helperTextError: PropTypes.string
};

export default React.memo(FinalFormTextField);
