const { convertDate } = require('../lib/shared-helpers.cjs');

const categories = [{
	id: 'general',
	title: 'Allgemein'
}, {
	id: 'retail',
	title: 'Einzelhandel',
}, {
	id: 'wholesale',
	title: 'Großhandel'
}, {
	id: 'czech',
	title: 'Tschechei'
}, {
	id: 'factory',
	title: 'Holzfabrik Erzgebirge'
}];

const labels = [{
	id: 'isPublic',
	field: 'isPublic',
	title: 'öffentlich',
	filter: true
}, {
	id: 'isNotPublic',
	field: 'isPublic',
	title: 'nicht öffentlich',
	filter: false
}, {
	id: 'isUpcoming',
	title: 'noch nicht öffentlich',
	filter: true
}, {
	id: 'isOutdated',
	title: 'nicht mehr öffentlich',
	filter: true
}, {
	id: 'isNewsEntry',
	title: 'News',
	filter: true
}, {
	id: 'isEvent',
	title: 'Event',
	filter: true
}, {
	id: 'isNotification',
	title: 'Notify',
	filter: true
}, {
	id: 'isFeatured',
	title: 'Vorgestellt',
	filter: true
}];

const generalEvents = [{
	id: 'allgemein',
	title: 'Allgemein',
	titlePlural: 'Allgemein',
	bookable: false,
	order: 0
}, {
	id: 'aktionswoche',
	title: 'Aktionswoche',
	titlePlural: 'Aktionswochen',
	pageId: 'events',
	bookable: false,
	order: 2
}];

const retailEvents = [{
	id: 'grillseminar',
	title: 'Grillseminar',
	titlePlural: 'Grillseminare',
	pageId: 'events1',
	bookable: true,
	order: 0
}, {
	id: 'aktionstag',
	title: 'Aktionstag',
	titlePlural: 'Aktionstage',
	pageId: 'events',
	bookable: false,
	order: 1
}, {
	id: 'messe',
	title: 'Messe',
	titlePlural: 'Messen',
	pageId: 'events',
	bookable: false,
	order: 3
}, {
	id: 'workshop',
	title: 'Workshop',
	titlePlural: 'Workshops',
	pageId: 'events',
	bookable: false,
	order: 4
}, {
	id: 'vorgestellt',
	title: 'Vorstellung / Premiere',
	titlePlural: 'Vorstellungen & Premieren',
	pageId: 'events',
	bookable: false,
	order: 5
}, {
	id: 'baumpflanzaktion',
	title: 'Baumpflanzaktion',
	titlePlural: 'Baumpflanzaktion',
	pageId: 'events2',
	bookable: true,
	order: 6
}, {
	...generalEvents[0]
}, {
	...generalEvents[1]
}];

const wholesaleEvent = [{
	id:	'schulung',
	title: 'Schulung',
	titlePlural: 'Schulungen',
	pageId: 'woodAcademy',
	bookable: true,
	order: 0
}, {
	id:	'seminar',
	title: 'Seminar',
	titlePlural: 'Seminare',
	pageId: 'woodAcademy',
	bookable: true,
	order: 1
}, {
	id:	'werksbesuch',
	title: 'Werksbesuch',
	titlePlural: 'Werksbesuche',
	pageId: 'woodAcademy',
	bookable: true,
	order: 2
}, {
	id:	'handwerkerfruehstueck',
	title: 'Handwerkerfrühstück',
	titlePlural: 'Handwerkerfrühstück',
	pageId: 'woodAcademy',
	bookable: false,
	order: 3
}, {
	...generalEvents[0]
}, {
	...generalEvents[1]
}];

const fieldDefs = {
	title: {
		id: 'title',
		label: 'Titel'
	},
	subtitle: {
		id: 'subtitle',
		label: 'Untertitel'
	},
	timestamp: {
		id: 'timestamp',
		label: 'Erstellt',
		value: convertDate
	},
	publicFrom: {
		id: 'publicFrom',
		label: 'Öffentlich von',
		value: convertDate
	},
	publicUntil: {
		id: 'publicUntil',
		label: 'Öffentlich bis',
		value: convertDate
	}
};

const filter = {
	limit: 12,
	sort: {
		publicFrom: -1,
		timestamp: -1
	}
};

module.exports = {
	filter,
	fields: [{
		...fieldDefs.title
	}, {
		...fieldDefs.subtitle
	}, {
		...fieldDefs.publicFrom
	}, {
		...fieldDefs.publicUntil
	}, {
		...fieldDefs.timestamp
	}],
	labels,
	categories,
	eventCategories: {
		general: generalEvents,
		retail: retailEvents,
		wholesale: wholesaleEvent
	}
};
