import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '../../styles';
import Stack from '../../mui/material/Stack';
import Typography from '../../mui/material/Typography';

const useStyles = createStyles((theme, {dense, HeadingProps, SubheadingProps}) => ({
	root: {},
	heading: {
		whiteSpace: 'pre-line',
		fontSize: {
			xs: theme.typography.pxToRem(dense ? 13 : 14),
			sm: theme.typography.pxToRem(dense ? 15 : 16)
		},
		color: theme.config.palette.text.primary,
		fontWeight: theme.typography.fontWeightBold,
		lineHeight: dense ? 1.2 : 1.3,
		...(HeadingProps?.lineClamp && {
			...theme.mixins.lineClamp,
			WebkitLineClamp: HeadingProps?.lineClamp
		})
	},
	subheading: {
		whiteSpace: 'pre-line',
		fontSize: {
			xs: theme.typography.pxToRem(dense ? 12 : 13),
			sm: theme.typography.pxToRem(dense ? 13 : 14)
		},
		lineHeight: dense ? 1.2 : 1.3,
		color: theme.config.palette.text.secondary,
		...(SubheadingProps?.lineClamp && {
			...theme.mixins.lineClamp,
			WebkitLineClamp: SubheadingProps?.lineClamp
		})
	}
}), {
	name: 'RaCardTitle'
});

function CardTitle(props) {
	const {
		classes: classesProp,
		className,
		children,
		subtitle,
		dense,
		HeadingProps: {
			lineClamp: lineClampHeading,
			...HeadingProps
		} = {},
		SubheadingProps: {
			lineClamp: lineClampSubheading,
			...SubheadingProps
		} = {},
		...rest
	} = props;

	const { classes } = useStyles(props);

	return (
		<Stack
			width="100%"
			flexWrap="wrap"
			alignItems="flex-start"
			justifyContent="center"
			{...rest}
			className={classes.root}
		>
			{children &&
				<Typography
					variant="h6"
					component="h2"
					color="inherit"
					className={classes.heading}
					{...HeadingProps}
				>
					{children}
				</Typography>
			}

			{subtitle &&
				<Typography
					variant="subtitle2"
					component="h3"
					color="inherit"
					className={classes.subheading}
					{...SubheadingProps}
				>
					{subtitle}
				</Typography>
			}
		</Stack>
	);
}

CardTitle.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	subtitle: PropTypes.node,
	HeadingProps: PropTypes.object,
	SubheadingProps: PropTypes.object,
	dense: PropTypes.bool
};

export default React.memo(CardTitle);
