import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import createCache from '@emotion/cache';
import { insertStyles, getRegisteredStyles } from '@emotion/utils';
import { css, __unsafe_useEmotionCache as useEmotionCache, CacheProvider } from '@emotion/react';
import { serializeStyles } from '@emotion/serialize';
import { unstable_styleFunctionSx as styleFnSx } from '@mui/system';
import { deepmerge, isPlainObject, getCookie, DEV } from '../lib/helpers';
import { useDidMount } from '../hooks';
import { createTheme, extendTheme } from './theme';
import useDetectColorScheme from '../hooks/useDetectColorScheme';
import { useApp } from '../context/app';
import {
	ThemeProvider as MuiThemeProvider,
	Experimental_CssVarsProvider as MuiCssVarsProvider,
	useColorScheme,
	useTheme
} from '@mui/material/styles';

const iterateObject = (obj = {}, cb) => {
	for (let [key, val] of Object.entries(obj)) {
		if (isPlainObject(val)) {
			iterateObject(val, cb, obj);
		}
		if (typeof cb === 'function') {
			cb(key, val, obj);
		}
	}
};

const createClassNames = (args = [], cache) => {
	const serialized = serializeStyles(args, cache.registered);
	insertStyles(cache, serialized, false);
	return `${cache.key}-${serialized.name}`;
};

const mergeClassNames = (className, cache) => {
	const registeredStyles = [];

	const rawClassName = getRegisteredStyles(
		cache.registered,
		registeredStyles,
		className
	);

	if (registeredStyles.length < 2) {
		return className;
	}

	return rawClassName + createClassNames(registeredStyles, cache);
};

export * from './theme';

export {
	CacheProvider,
	useEmotionCache,
	useTheme,
	useColorScheme,
	clsx,
	css,
	styleFnSx,
	deepmerge
};

export { useThemeProps } from './utils';

export const cacheOptions = {
	key: 'css',
	...(DEV && { stylisPlugins: [] }),
	get nonce() {
		return global.__webpack_nonce__ || global.__style_nonce__;
	}
};

export function createEmotionCache({compat = true, ...options} = {}) {
	const cache = createCache({
		...cacheOptions,
		...options
	});
	cache.compat = compat;
	return cache;
}

export function makeStyles(fn, theme = {}, props = {}, name) {
	const sx = (styles = {}) => styleFnSx({
		theme,
		sx: styles
	});

	const styles = typeof fn === 'function' ? fn(theme, props, sx) : fn || {};

	const { styleOverrides } = theme?.components?.[name] || {};

	const getStyleOverwrites = () => {
		if (styleOverrides) {
			return Object.fromEntries(Object.entries(styleOverrides).map(([key, val]) => ([
				key, typeof val === 'function' ? val({ ownerState: props, theme}) : val
			])));
		}
		return null;
	};

	return props?.styles || styleOverrides ? deepmerge.all([
		styles,
		getStyleOverwrites(),
		props?.styles
	]) : styles;
}

export function createSxStyles(fn, {name} = {}) {
	return (props) => {
		const theme = useTheme();
		return React.useMemo(() => {
			const styles = makeStyles(fn, theme, props, name);

			if (props?.sx) {
				styles.root = cx(styles.root, props.sx);
			}

			return DEV ? Object.fromEntries(
				Object.keys(styles).map(key => [key, {
					label: [name, key].filter(Boolean).join('-'),
					...styles[key]
				}])
			) : styles;
		}, [theme, props]);
	};
}

export function createClasses(fn, {name, enableSx = true, mergeClassName = true} = {}) {
	return (props) => {
		const theme = useTheme();
		const { cxCls, cssCls, sxCls } = useClsUtils();
		const styleFn = enableSx ? sxCls : cssCls;

		return React.useMemo(() => {
			const styles = makeStyles(fn, theme, props, name);
			return Object.fromEntries(
				Object.keys(styles).map(key => [key,
					cxCls(styleFn(DEV ? {
						label: [name, key].filter(Boolean).join('-'),
						...styles[key]
					} : styles[key]), props?.classes?.[key], mergeClassName && key === 'root' && props?.className)
				])
			);
		}, [theme, props, cxCls, styleFn]);
	};
}

export function createStyles(fn, {name, enableSx = true, mergeClassName = true} = {}) {
	const useStyles = createSxStyles(fn, {name});

	return (props) => {
		const styles = useStyles(props);
		const classes = useClasses(styles, {...props, enableSx, mergeClassName});
		return {
			styles,
			classes
		};
	};
}

export function useClasses(styles = {}, {className, classes, enableSx = true, mergeClassName = true} = {}) {
	const { cxCls, sxCls, cssCls } = useClsUtils();

	const styleFn = enableSx ? sxCls : cssCls;

	return React.useMemo(() => (
		Object.fromEntries(
			Object.keys(styles).map(key => {
				const value = [
					key,
					cxCls(styleFn(styles[key]), classes?.[key], mergeClassName && key === 'root' && className)
				];
				return value;
			})
		)
	), [styles, className, classes, mergeClassName, cxCls, styleFn]);
}

export function cx(...args) {
	return deepmerge.all([...args.filter(Boolean)]);
}

export function useSx() {
	const theme = useTheme();
	return (styles = {}) => styleFnSx({
		theme,
		sx: styles
	});
}

export function useClsUtils() {
	const sx = useSx();
	const cache = useEmotionCache();
	if (!cache) {
		throw new TypeError('Emotion cache missing!');
	}

	return React.useMemo(() => {
		const cssCls = (...args) => createClassNames(args, cache);
		const cxCls = (...args) => mergeClassNames(clsx(args), cache);
		const sxCls = (...args) => createClassNames(args.map(sx), cache);
		const cxsx = (...args) => cxCls(...args.map(arg => typeof arg === 'string' ? arg : sxCls(arg)));

		return {
			cssCls,
			cxCls,
			sxCls,
			cxsx
		};
	}, [cache, sx]);
}

export function ThemeProvider(props) {
	const {
		createTheme = extendTheme,
		themeConfig,
		config,
		colorSchemeSupport = true,
		...rest
	} = props;

	const colorMode = useDetectColorMode();

	const lightTheme = colorSchemeSupport ? createTheme(themeConfig, {
		...config,
		mode: 'light'
	}) : null;

	const darkTheme = colorSchemeSupport ? createTheme(themeConfig, {
		...config,
		mode: 'dark'
	}) : null;

	const theme = colorSchemeSupport
		? colorMode === 'dark' ? darkTheme : lightTheme
		: createTheme(themeConfig, config);

	return (
		colorSchemeSupport ? (
			<ColorModeProvider lightTheme={lightTheme} darkTheme={darkTheme}>
				<MuiThemeProvider {...rest} theme={theme}/>
			</ColorModeProvider>
		) : (
			<MuiThemeProvider {...rest} theme={theme}/>
		)
	);
}

function ColoModeSwitch({children, enableMuiColorScheme}) {
	const [{ colorMode }, { setColorMode }] = useApp();
	const { mode, setMode } = useColorScheme();

	React.useEffect(() => {
		if (enableMuiColorScheme) {
			if (mode !== colorMode) {
				setMode(colorMode);
				setColorMode(colorMode);
			}
		}
	}, [mode, setMode, colorMode, setColorMode, enableMuiColorScheme]);

	return children;
}

export function CssVarsProvider(props) {
	const {
		children,
		createTheme = extendTheme,
		themeConfig,
		prefix,
		config,
		enableMuiColorScheme = true,
		...rest
	} = props;

	const [{ colorMode }] = useApp();

	const lightTheme = createTheme(themeConfig, {
		...config,
		mode: 'light',
		cssVarPrefix: prefix,
		cssVarsMode: true
	});

	const darkTheme = createTheme(themeConfig, {
		...config,
		mode: 'dark',
		cssVarPrefix: prefix,
		cssVarsMode: true
	});

	const theme = colorMode === 'dark' ? darkTheme : lightTheme;

	return (
		<ColorModeProvider cssVarsMode lightTheme={lightTheme} darkTheme={darkTheme}>
			<MuiCssVarsProvider
				enableColorScheme
				{...rest}
				defaultMode={colorMode}
				theme={theme}
				prefix={prefix}
			>
				<ColoModeSwitch enableMuiColorScheme={enableMuiColorScheme}>
					{children}
				</ColoModeSwitch>
			</MuiCssVarsProvider>
		</ColorModeProvider>
	);
}

export function useDetectColorMode() {
	const [{ colorMode }, { setColorMode }] = useApp();
	const preferdColorMode = useDetectColorScheme(colorMode);

	React.useEffect(() => {
		if (!getCookie('colorMode') && preferdColorMode !== colorMode) {
			setColorMode(preferdColorMode, false);
		}
	}, [colorMode, setColorMode, preferdColorMode]);

	return colorMode;
}

export const ColorModeContext = React.createContext();

export function useColorModeValue(...args) {
	const theme = useTheme();
	return theme.helpers.colorModeValue(...args);
}

export const useColorMode = (mode) => {
	const darkMode = useColorModeValue(true, false);
	const themes = React.useContext(ColorModeContext);
	const theme = useTheme();
	if (!themes) {
		return theme;
	}
	return themes?.[mode] || (darkMode ? themes.light : themes.dark);
};

export function ColorMode(props) {
	const theme = useColorMode(props.mode);
	return (
		<MuiThemeProvider theme={theme}>
			{props.children}
		</MuiThemeProvider>
	);
}

export function ColorModeProvider(props) {
	const {
		cssVarsMode,
		lightTheme = createTheme(),
		darkTheme = createTheme({palette: {mode: 'dark'}})
	} = props;

	const themes = {
		light: lightTheme,
		dark: darkTheme
	};

	if (cssVarsMode) {
		themes.light.vars.palette = lightTheme.colorSchemes.light.palette;
		themes.dark.vars.palette = darkTheme.colorSchemes.dark.palette;
	}

	return (
		<ColorModeContext.Provider value={themes}>
			{props.children}
		</ColorModeContext.Provider>
	);
}

ColorModeProvider.propTypes = {
	lightTheme: PropTypes.object,
	darkTheme: PropTypes.object
};

export const ClientStylesContext = React.createContext({
	reset: () => {}
});

export function useClientStyles(disabled = false) {
	const [, startTransition] = React.useTransition();
	const emotionCache = useEmotionCache();
	const clientStyleData = React.useContext(ClientStylesContext);

	useDidMount(() => {
		if (disabled) {
			return;
		}
		const resetCache = () => {
			startTransition(() => {
				emotionCache.sheet.container = document.head;
				const tags = emotionCache.sheet.tags;
				emotionCache.sheet.flush();
				tags.forEach((tag) => {
					emotionCache.sheet._insertTag(tag);
				});
				clientStyleData.reset();
			});
		};
		if (typeof requestIdleCallback === 'function') {
			resetCache();
		} else {
			setTimeout(resetCache, 1);
		}
	});
}

export function ClientCacheProvider({ children, emotionCache, getEmotionCache }) {
	const [cache, setCache] = React.useState(emotionCache);

	const clientStyleContextValue = React.useMemo(() => ({
		reset() {
			setCache(getEmotionCache());
		}}
	), [getEmotionCache]);

	return (
		<ClientStylesContext.Provider value={clientStyleContextValue}>
			<CacheProvider value={cache}>
				{children}
			</CacheProvider>
		</ClientStylesContext.Provider>
	);
}
