import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '../../styles';
import Typography from '../../mui/material/Typography';
import Divider from '../../mui/material/Divider';

const useStyles = createStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: theme.spacing(4, 'auto'),
		padding: theme.spacing(2)
	},
	divider: {
		margin: theme.spacing(0, 2),
		backgroundColor: theme.helpers.alpha('text-primary', .4),
		[theme.breakpoints.down('tablet')]: {
			margin: theme.spacing(0, 1)
		}
	},
	title: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down('tablet')]: {
			fontSize: theme.typography.pxToRem(24)
		}
	},
	description: {
		marginBottom: theme.spacing(1.5)
	}
}), {
	name: 'Error'
});

function Error(props) {
	const {
		classes: classesProp,
		className,
		children,
		statusCode,
		title,
		description,
		...rest
	} = props;

	const { classes } = useStyles(props);

	return (
		<div
			className={classes.root}
		>
			<Typography
				variant="h4"
				color="inherit"
				component="h1"
				{...rest}
				className={classes.title}
			>
				{Number.isFinite(statusCode) && (
					<>
						<strong>
							{statusCode}
						</strong>
						<Divider
							flexItem
							orientation="vertical"
							className={classes.divider}
						/>
					</>
				)}
				<small>
					{title}
				</small>
			</Typography>
			<Typography
				textAlign="center"
				className={classes.description}
			>
				{description}
			</Typography>
			{children}
		</div>
	);
}

Error.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	statusCode: PropTypes.number,
	title: PropTypes.string.isRequired,
	description: PropTypes.string
};

export default React.memo(Error);
