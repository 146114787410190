import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles, clsx } from '../../styles';
import Iframe from '../Iframe';
import Img from '../Img';
import FullscreenDialog from '../FullscreenDialog';
import NavButton from '../NavButton';
import { isImage, isLink } from '../../lib/helpers';
import { useMemoCallback } from '../../hooks';

const useStyles = createStyles((theme, {noTransform}) => ({
	root: {},
	content: {
		overflow: 'hidden'
	},
	container: {
		width: '100%',
		position: 'relative',
		display: 'flex',
		transition: theme.transitions.create(noTransform ? 'margin' : 'transform', {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.complex
		})
	},
	slide: {
		width: '100%',
		maxWidth: '100%',
		maxHeight: '100%',
		flex: '0 0 100%',
		display: 'flex',
		alignSelf: 'center',
		overflow: 'auto'
	},
	item: {
		margin: 'auto'
	}
}), {
	name: 'RaFullscreenCarouselDialog'
});

function FullscreenCarouselDialog(props) {
	const {
		classes: classesProp,
		className,
		children,
		title,
		src: srcProp,
		open,
		centered,
		onClose,
		imgProps,
		ImgProps: ImgPropsProp,
		IframeProps,
		defaultCursor = 0,
		...rest
	} = props;

	const { classes } = useStyles(props);

	const [cursor, setCursor] = React.useState(defaultCursor);

	const src = Array.isArray(srcProp)
		? srcProp : typeof srcProp === 'string'
			? srcProp.split(',') : [srcProp];

	const multiSrc = Array.isArray(src) && src.length > 1;
	const isImageSrc = !multiSrc && isImage(src[0]);
	const isLinkSrc = !multiSrc && isLink(src[0]);

	const ImgProps = {
		title,
		responsive: false,
		lazyload: false,
		...ImgPropsProp,
		imgProps,
		className: clsx(classes.item, ImgPropsProp?.className)
	};

	/* const goTo = useMemoCallback((cursor) => {
		if (cursor >= 0 && cursor <= src.length) {
			setCursor(cursor);
		} else if (cursor < 0) {
			setCursor(src.length - 1);
		} else {
			setCursor(0);
		}
	}); */

	const next = useMemoCallback(() => {
		if (cursor + 1 < src.length) {
			setCursor(cursor + 1);
		} else {
			setCursor(0);
		}
	});

	const prev = useMemoCallback(() => {
		if (cursor - 1 < 0) {
			setCursor(src.length - 1);
		} else {
			setCursor(cursor - 1);
		}
	});

	return (
		<FullscreenDialog
			onClose={onClose}
			open={open}
			title={title}
			{...rest}
			classes={{
				root: classes.root,
				content: classes.content
			}}
			centered={centered}
		>
			{multiSrc ? (
				<>
					<div
						className={classes.container}
						style={{
							transform: `translate3d(-${(cursor) * 100}%, 0, 0)`
						}}
					>
						{src.map((itemSrc, key) => {
							const currentSrc = itemSrc;
							const isImageSrc = isImage(currentSrc);
							const isLinkSrc = isLink(currentSrc);

							return (
								isImageSrc ? (
									<div key={key} className={classes.slide}>
										<Img
											{...ImgProps}
											src={currentSrc}
										/>
									</div>
								) : isLinkSrc ? (
									<Iframe
										{...IframeProps}
										src={currentSrc}
									/>
								) : null
							);
						})}
					</div>

					<NavButton
						hideOnTouchDevices={false}
						onClick={prev}
						flat
					/>
					<NavButton
						hideOnTouchDevices={false}
						onClick={next}
						direction="right"
						flat
					/>
				</>
			) : (
				isImageSrc ? (
					<div className={classes.slide}>
						<Img
							{...ImgProps}
							src={src[0]}
						/>
					</div>
				) : isLinkSrc ? (
					<Iframe
						{...IframeProps}
						src={src[0]}
					/>
				) : children
			)}
		</FullscreenDialog>
	);
}

FullscreenCarouselDialog.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	src: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	open: PropTypes.bool,
	centered: PropTypes.bool,
	onClose: PropTypes.func,
	imgProps: PropTypes.object,
	ImgProps: PropTypes.object,
	IframeProps: PropTypes.object
};

export default React.memo(FullscreenCarouselDialog);
