/* global WEBPACK_DEV */

import * as React from 'react';
import PropTypes from 'prop-types';
import { useDeferredUser } from 'context/user';
import FinalForm from 'react-app/components/FinalForm';
import {
	FormTextField,
	FormGridItem,
	FormOfAddressFromField,
	EmailFormField,
	MessageFormField,
	FormSubmitButton,
	FormResetButton,
	PrivacyPolicyCheckbox,
	RequiredHint
} from 'react-app/components/FinalForm/components';

const initialValues = WEBPACK_DEV ? {
	email: 'm.teichmann@holzweidauer.com',
	privacyPolicy: true,
	formOfAddress: 'mr',
	firstname: 'John',
	lastname: 'Doe',
	phone: '03 71 / 8 42 29 0',
	company: 'Weidauer Holzhandel GmbH',
	street: 'Oberfrohnaer Straße 59',
	zip: '09117',
	city: 'Chemnitz',
	comment: 'Kommentar',
	participants: 1
} : {};

const validateQuantity = (value => !isNaN(+value) && +value > 0 ? undefined : true);

function EventBookingForm(props) {
	const [{ userType, isWholesale }] = useDeferredUser();

	const {
		classes: classesProp,
		className,
		selectedItems: selectedItemsProp = [],
		submitButtonLabel = 'Absenden',
		companyRequired = isWholesale,
		phoneRequired = false,
		extraValues,
		...rest
	} = props;

	function renderForm(formProps) {
		const {
			submitting,
			resetForm,
			disabled: disabledProp,
			values
		} = formProps;

		const FormTextFieldProps = {
			submitting
		};

		return (
			<>
				<FormGridItem xs md={3}>
					<FormTextField
						required
						label="Teilnehmer"
						name="participants"
						type="number"
						helperTextError="Teilnehmerzahl ungültig"
						inputProps={{min: 1}}
						validate={validateQuantity}
						{...FormTextFieldProps}
					/>
				</FormGridItem>

				<FormGridItem xs md={3}>
					<FormOfAddressFromField required {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem xs={12} md>
					<FormTextField required name="firstname" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem xs={12} md>
					<FormTextField required name="lastname" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem>
					<FormTextField required={companyRequired} name="company" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem md={6}>
					<EmailFormField required {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem md={6}>
					<FormTextField required={phoneRequired} name="phone" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem>
					<FormTextField required name="street" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem md={5}>
					<FormTextField required name="zip" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem md={7}>
					<FormTextField required name="city" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem>
					<MessageFormField name="comment" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem>
					<PrivacyPolicyCheckbox/>
				</FormGridItem>

				<FormGridItem sm={1} md={2}/>

				<FormGridItem sm={5} md={4}>
					<FormSubmitButton
						size="small"
						variant="outlined"
						label={submitButtonLabel}
					/>
				</FormGridItem>

				<FormGridItem sm={5} md={4}>
					<FormResetButton
						size="small"
						variant="outlined"
						onClick={resetForm}
					/>
				</FormGridItem>

				<FormGridItem>
					<RequiredHint/>
				</FormGridItem>
			</>
		);
	}

	return (
		<FinalForm
			{...rest}
			renderForm={renderForm}
			initialValues={initialValues}
			fetchUrl="/api/forms/event-booking"
			extraValues={{
				...extraValues,
				userType
			}}
		/>
	);
}

EventBookingForm.propTypes = {
	extraValues: PropTypes.object,
	selectedItems: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		subtitle: PropTypes.string,
		price: PropTypes.number,
		unit: PropTypes.string,
		itemNo: PropTypes.string,
		imageSrc: PropTypes.string
	}))
};

export default React.memo(EventBookingForm);
