import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';
import Container from '@mui/material/Container';

const useClasses = createClasses((theme, {disableGuttersX, disableGuttersY, fullWidth}) => ({
	root: {
		...theme.mixins.flexContainer,
		position: 'relative',
		alignItems: 'center',
		...(!disableGuttersX && {
			paddingX: theme.config.containerSpacing
		}),
		...(!disableGuttersY && {
			marginY: theme.config.layoutSpacing
		}),
		...(fullWidth && {
			'&&': {
				paddingLeft: 0,
				paddingRight: 0
			}
		})
	}
}), {
	name: 'AppContent'
});

function AppContent(props) {
	const {
		classes: classesProp,
		className,
		children,
		fullWidth,
		disableGuttersY,
		disableGuttersX,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Container
			maxWidth={false}
			component="main"
			{...rest}
			disableGutters
			className={classes.root}
		>
			{children}
		</Container>
	);
}

AppContent.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	disableGuttersX: PropTypes.bool,
	disableGuttersY: PropTypes.bool,
	fullWidth: PropTypes.bool
};


export default React.memo(AppContent);
