import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles, clsx } from '../../styles';
import AppBar from '../../mui/material/AppBar';
import Toolbar from '../../mui/material/Toolbar';
import Button from '../Button';
import GetAppIcon from '../../mui/icons-material/GetApp';
import DownloadIcon from '../../mui/icons-material/Download';
import { HOST_URL, isMobileDevice } from '../../lib/helpers';
import { useMemoCallback } from '../../hooks';

const useStyles = createStyles((theme, {fullWidth, fullScreen}) => ({
	root: {
		backgroundColor: theme.config.palette.background.default,
		color: 'white',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		...(fullWidth && {
			width: '100%'
		}),
		...(fullScreen && {
			width: '100%',
			minHeight: '100%'
		})
	},
	iframe: {
		backgroundColor: 'inherit',
		overflow: 'hidden',
		flexGrow: 1,
		zIndex: 200,
		border: 0
	},
	spinner: {
		...theme.mixins.spinner,
		overflow: 'hidden',
		'&:before': {
			...theme.mixins.spinner['&:before'],
			zIndex: 300
		}
	},
	button: {
		margin: 'auto'
	},
	appBar: {
		top: 'auto',
		bottom: 0,
		color: theme.config.palette.common.white,
		backgroundColor: theme.config.darkMode ? theme.config.palette.background.level2 : theme.config.palette.grey[900],
		boxShadow: [
			'0px -2px 4px 1px rgba(0,0,0,0.2)',
			'0px -1px 10px 0px rgba(0,0,0,0.12)'
		].join(', '),
		[theme.config.mediaQueries.noTouch]: {
			display: 'none'
		}
	}
}), {
	name: 'RaIframe',
	mergeClassName: false
});

function Iframe(props) {
	const iframeRef = React.useRef();
	const timerRef = React.useRef();

	const {
		classes: classesProp,
		className,
		src,
		fullWidth,
		fullScreen,
		disableLoading,
		...rest
	} = props;

	const { classes } = useStyles(props);
	const [loading, setLoading] = React.useState(false);
	const isPdf = (/\.pdf$/i).test(src) || (/\.pdf#zoom=/i).test(src);

	React.useEffect(() => {
		const iframeNode = iframeRef.current;
		const isPdf = (/\.pdf$/i).test(src) || (/\.pdf#zoom=/i).test(src);

		if (isPdf && isMobileDevice) {
			iframeNode.src = `https://docs.google.com/gview?embedded=true&url=${HOST_URL + src}`;
		} else if (iframeNode && !iframeNode.src) {
			if (!disableLoading) {
				timerRef.current = setTimeout(() => setLoading(true), 2000);
			}
			iframeNode.src = src;
		}

		return () => {
			clearTimeout(timerRef.current);
		};
	}, [src, disableLoading]);

	const handleOnLoad = useMemoCallback(() => {
		clearTimeout(timerRef.current);
		if (loading) {
			setLoading(false);
		}
	});

	return (
		<div
			className={clsx(
				classes.root,
				loading && classes.spinner,
				className
			)}
		>
			<iframe
				ref={iframeRef}
				onLoad={handleOnLoad}
				allow="autoplay; fullscreen"
				allowtransparency="true"
				{...rest}
				className={classes.iframe}
			/>
			{isPdf ? (
				<AppBar
					position="sticky"
					className={classes.appBar}
				>
					<Toolbar variant="dense">
						<Button
							component="a"
							className={classes.button}
							href={src}
							target="_blank"
							color="inherit"
							startIcon={<DownloadIcon/>}
						>
							PDF herunterladen
						</Button>
					</Toolbar>
				</AppBar>
			) : null}
		</div>
	);
}

Iframe.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	src: PropTypes.string.isRequired,
	fullWidth: PropTypes.bool,
	fullScreen: PropTypes.bool,
	disableLoading: PropTypes.bool
};

export default React.memo(Iframe);
