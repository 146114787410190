import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, useClsUtils } from 'styles';
import CardMedia from 'react-app/components/CardMedia';
import CardMediaCarousel from 'react-app/components/CardMediaCarousel';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import { isImage } from 'lib/helpers';

const useClasses = createClasses((theme, {imageScrollable}) => ({
	root: {},
	image: {
		backgroundColor: theme.config.palette.background.placeholder,
		// minHeight: 216,
		// transition: theme.transitions.create('padding', {
		// 	easing: theme.transitions.easing.easeInOut,
		// 	duration: theme.transitions.duration.standard
		// }),
		...(!imageScrollable && {
			width: '100%',
			objectFit: 'cover',
			objectPosition: 'center'
		})
	},
	playIcon: {
		color: 'white',
		filter: 'drop-shadow(1px 1px 2px rgba(0,0,0,.3))',
		width: '40%',
		height: '40%',
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
		top: '50%',
		left: '50%'
	}
}), {
	name: 'NewsMedia'
});

function NewsMedia(props) {
	const {
		className,
		children,
		imageSrc: imageSrcProp,
		youtubeParams: youtubeParamsProp,
		ImgClassName,
		videoButton: video,
		filesData,
		carouselMode,
		imageScrollable,
		...rest
	} = props;

	const { cxCls } = useClsUtils();
	const classes = useClasses(props);

	const youtubeParams = youtubeParamsProp || {};
	const imageSrc = imageSrcProp || youtubeParams.imageSrc;
	const images = Array.isArray(filesData) ? filesData.filter(item => isImage(item.fileName)).map(item => item.fileSrc) : [];
	const multiImages = carouselMode && images.length > 1;

	const CardMediaComponent = multiImages ? CardMediaCarousel : CardMedia;

	return (imageSrc || multiImages) ? (
		<CardMediaComponent
			{...rest}
			src={multiImages ? images : imageSrc}
			className={cxCls(
				classes.root,
				video && classes.video,
				className
			)}
			ImgClassName={cxCls(
				classes.image,
				ImgClassName
			)}
		>
			{video &&
				<PlayIcon className={classes.playIcon}/>
			}
			{children}
		</CardMediaComponent>
	) : null;
}

NewsMedia.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	imageSrc: PropTypes.string,
	youtubeParams: PropTypes.object,
	ImgClassName: PropTypes.string,
	carouselMode: PropTypes.bool
};

export default React.memo(NewsMedia);
