import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles, clsx } from '../../styles';
import MuiAppBar from '../../mui/material/AppBar';
import Toolbar from '../../mui/material/Toolbar';
import IconButton from '../../mui/material/IconButton';
import MenuIcon from '../../mui/icons-material/Menu';

const useStyles = createStyles((theme) => {
	return {
		root: {
			alignSelf: 'flex-start'
		},
		extended: {},
		toolbar: {
			overflow: 'hidden',
			justifyContent: 'space-between',
			padding: theme.spacing(0, 1),
			[theme.breakpoints.only('xs')]: {
				padding: theme.spacing(0, 1)
			}
		},
		extendedToolbar: {},
		menuButton: {
			padding: theme.spacing(1),
			marginRight: theme.spacing(1),
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(.5),
				marginRight: theme.spacing(2)
			}
		},
		hide: {
			display: 'none'
		},
		toolbarLeft: {},
		toolbarMiddle: {},
		toolbarRight: {}
	};
}, {
	name: 'RaAppBar',
	mergeClassName: false
});

const AppBar = React.forwardRef(function AppBar(props, ref) {
	const {
		classes: classesProp,
		className,
		children,
		noToolebar,
		toolbarContent,
		toolbarMiddleContent,
		extendedToolbarContent,
		toolbarRightContent,
		toolbarLeftContent,
		onToggle,
		ToolbarProps,
		ToolbarContainerProps,
		ToolbarLeftProps,
		ToolbarMiddleProps,
		ToolbarRightProps,
		ToolbarExtendedProps,
		MenuIconComponent = MenuIcon,
		...rest
	} = props;

	const { classes } = useStyles(props);

	return (
		<MuiAppBar
			position="sticky"
			variant="elevation"
			{...rest}
			ref={ref}
			className={clsx(
				classes.root,
				extendedToolbarContent && classes.extended,
				className
			)}
		>
			{toolbarContent ? toolbarContent : noToolebar ? null : (
				<Toolbar
					{...ToolbarProps}
					disableGutters
					className={classes.toolbar}
				>
					{(toolbarLeftContent || onToggle) &&
						<div className={classes.toolbarLeft}>
							{onToggle &&
								<IconButton
									color="inherit"
									onClick={onToggle}
									className={classes.menuButton}
									aria-label="Navigationsmenü öffnen"
								>
									<MenuIconComponent/>
								</IconButton>
							}

							{toolbarLeftContent}
						</div>
					}

					{toolbarMiddleContent && (
						<div className={classes.toolbarMiddle}>
							{toolbarMiddleContent}
						</div>
					)}

					{toolbarRightContent && (
						<div className={classes.toolbarRight}>
							{toolbarRightContent}
						</div>
					)}
				</Toolbar>
			)}
			{extendedToolbarContent &&
				<Toolbar
					disableGutters
					{...ToolbarExtendedProps}
					className={clsx(
						classes.toolbar,
						classes.extendedToolbar
					)}
				>
					{extendedToolbarContent}
				</Toolbar>
			}
			{children}
		</MuiAppBar>
	);
});

AppBar.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	onToggle: PropTypes.func,
	toolbarContent: PropTypes.node,
	toolbarLeftContent: PropTypes.node,
	toolbarMiddleContent: PropTypes.node,
	extendedToolbarContent: PropTypes.node,
	toolbarRightContent: PropTypes.node,
	noToolebar: PropTypes.bool,
	ToolbarProps: PropTypes.object,
	ToolbarContainerProps: PropTypes.object,
	ToolbarLeftProps: PropTypes.object,
	ToolbarMiddleProps: PropTypes.object,
	ToolbarRightProps: PropTypes.object,
	ToolbarExtendedProps: PropTypes.object,
	MenuIconComponent: PropTypes.elementType
};

export default React.memo(AppBar);
