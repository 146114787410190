import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, useClsUtils } from 'styles';
import Card from 'react-app/components/Card';
import CardHeader from 'react-app/components/CardHeader';
import CardTitle from 'react-app/components/CardTitle';
import CardActions from 'react-app/components/CardActions';
import CardActionArea from 'components/CardActionArea';
import Divider from '@mui/material/Divider';
import Link from 'components/Link';
import Label from 'react-app/components/Label';
import { news as sizes } from 'config/sizes.cjs';
import NewsMedia from './NewsMedia';
import NewsContent from './NewsContent';
import NewsDialog from './NewsDialog';
import useNewsProps from './useNewsProps';

const useClasses = createClasses((theme, {showContent, fullWidth, maxWidth}) => ({
	root: {
		margin: 0,
		maxWidth: maxWidth || sizes.width.sm,
		alignSelf: 'center'
	},
	fullWidth: {
		maxWidth: sizes.maxWidth,
		background: 'none',
		border: 'none'
	},
	expanded: {},
	heading: {
		...(!showContent && {
			...theme.mixins.lineClamp
		})
	},
	subheading: {
		...theme.mixins.textOverflow,
		color: theme.config.palette.text.secondary,
		display: 'flex',
		alignItems: 'center'
	},
	subtitle: {
		...theme.mixins.textOverflow,
		marginTop: theme.spacing(.25),
		fontSize: theme.typography.pxToRem(12),
		[theme.breakpoints.up('md')]: {
			fontSize: theme.typography.pxToRem(14)
		}
	},
	divider: {
		width: '100%'
	},
	collapse: {
		width: '100%'
	},
	cardHeader: {
		...(fullWidth && {
			[theme.breakpoints.down('sm')]: {
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1)
			},
			'&&': {
				minHeight: {
					xs: theme.spacing(4),
					sm: theme.spacing(4),
					md: theme.spacing(6)
				}
			}
		})
	},
	cardSubheader: {
		justifyContent: 'flex-end',
		'& > :first-child': {
			marginRight: 'auto'
		},
		'& > *': {
			margin: theme.spacing(-1)
		}
	},
	cardActionArea: {
		borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
		...(fullWidth && {
			borderRadius: `${theme.shape.borderRadius}px`
		})
	},
	cardTitle: {
		...(!fullWidth && {
			minHeight: theme.spacing(7),
			[theme.breakpoints.up('md')]: {
				minHeight: theme.spacing(8)
			}
		})
	},
	cardSubtitle: {
		marginBottom: theme.spacing(2)
	},
	cardContent: {
		...(fullWidth && {
			[theme.breakpoints.down('sm')]: {
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1)
			}
		})
	},
	cardActions: {
		...(fullWidth && {
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 0,
				paddingRight: 0
			}
		})
	},
	label: {
		whiteSpace: 'nowrap',
		'&:first-child': {
			marginLeft: 0
		}
	},
	backButton: {
		marginLeft: theme.spacing(-1),
		marginRight: theme.spacing(1)
	}
}), {
	name: 'NewsCard'
});

function NewsCard(props) {
	const {
		className,
		id,
		title,
		subtitle,
		filesData,
		imageSrc,
		fullWidth = false,
		showContent = false,
		dialogMode,
		isPublic,
		isUpcoming,
		isVideoSrc,
		videoButton,
		youtubeParams,
		url,
		CardProps,
		showPublishedDate,
		NewsMediaProps: NewsMediaPropsProp,
		NewsDialogProps: NewsDialogPropsProp,
		NewsContentProps: NewsContentPropsProp
	} = props;

	const { cxCls } = useClsUtils();
	const classes = useClasses(props);

	const {
		dialogOpen,
		html,
		link,
		zoomImage,
		iframeSrc,
		hasContent,
		shopLink,
		pageDialogProps,
		handleDialogOpen,
		handleDialogClose,
		buttons,
		icons,
		hasButtons,
		hasIcons,
		timeAgoLabel,
		timeAgoSubtitle
	} = useNewsProps(props);

	const subtitleNode = React.useMemo(() => (
		<>
			{(timeAgoSubtitle || (fullWidth && timeAgoLabel)) && (
				<div className={classes.subtitle}>
					{fullWidth && showPublishedDate ? `Veröffentlicht ${timeAgoLabel}` : timeAgoSubtitle}
				</div>
			)}
			{(isUpcoming || !isPublic) &&
				<Label className={classes.label} color={isUpcoming && isPublic ? 'info' : 'error'}>
					{`${isUpcoming && isPublic ? 'noch ' : ''}nicht öffentlich`}
				</Label>
			}
		</>
	), [
		classes,
		fullWidth,
		timeAgoLabel,
		timeAgoSubtitle,
		isUpcoming,
		isPublic,
		showPublishedDate
	]);

	const cardTitleClasses = React.useMemo(() => ({
		heading: classes.heading,
		subheading: classes.subheading
	}), [classes]);

	const CardActionAreaProps = {
		className: classes.cardActionArea,
		...(!shopLink && (zoomImage || isVideoSrc || link) && (!html || (showContent && (videoButton || link))) ? {
			...pageDialogProps
		} : !showContent && url && !dialogMode ? {
			to: url
		} : shopLink ? {
			to: shopLink
		} : (!showContent && html) || zoomImage ? {
			onClick: handleDialogOpen
		} : null)
	};

	const NewsMediaProps = {
		...NewsMediaPropsProp,
		title,
		imageSrc,
		youtubeParams,
		videoButton: !showContent && html ? false : videoButton
	};

	const NewsContentProps = {
		...NewsContentPropsProp,
		subtitle,
		contentHtml: html
	};

	const NewsDialogProps = {
		...NewsDialogPropsProp,
		NewsMediaProps: {
			...NewsDialogPropsProp?.NewsMediaProps
		},
		title,
		subtitle,
		link,
		shopLink,
		imageSrc,
		iframeSrc,
		isVideoSrc,
		youtubeParams,
		filesData,
		contentHtml: html,
		url
	};

	const cardHeader = (
		<CardHeader className={classes.cardHeader}>
			<CardTitle
				subtitle={subtitleNode}
				className={classes.cardTitle}
				classes={cardTitleClasses}
			>
				{fullWidth ? null : title}
			</CardTitle>
		</CardHeader>
	);

	return (
		<>
			<Card
				{...CardProps}
				id={id}
				className={cxCls(
					classes.root,
					fullWidth && classes.fullWidth,
					className
				)}
			>
				<CardActionArea
					{...(((!showContent && (hasContent || imageSrc)) || videoButton || link || shopLink) && {
						component: CardActionAreaProps.onClick ? undefined : Link,
						...CardActionAreaProps
					})}
				>
					<NewsMedia
						width={sizes.width.xs}
						height={sizes.width.xs / sizes.aspectRatio}
						rounded
						{...NewsMediaProps}
					/>
					{!fullWidth && cardHeader}
				</CardActionArea>

				{fullWidth && cardHeader}

				{showContent && hasContent && (
					<>
						{!imageSrc && (
							<Divider className={classes.divider}/>
						)}
						<NewsContent {...NewsContentProps} className={classes.cardContent}/>
					</>
				)}

				{(hasButtons || hasIcons) &&
					<CardActions
						dense
						className={classes.cardActions}
						buttons={buttons}
						icons={icons}
					/>
				}
			</Card>

			{!showContent && (
				<NewsDialog
					{...NewsDialogProps}
					open={dialogOpen}
					onClose={handleDialogClose}
				/>
			)}
		</>
	);
}

NewsCard.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	publicFrom: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	contentHtml: PropTypes.string,
	link: PropTypes.string,
	imageSrc: PropTypes.string,
	imageSrcSet: PropTypes.object,
	fullWidth: PropTypes.bool,
	showContent: PropTypes.bool,
	isPublic: PropTypes.bool,
	isUpcoming: PropTypes.bool,
	dialogOnly: PropTypes.bool,
	showPublishedDate: PropTypes.bool,
	url: PropTypes.string,
	backUrl: PropTypes.string,
	CardProps: PropTypes.object,
	NewsMediaProps: PropTypes.object,
	NewsDialogProps: PropTypes.object,
	NewsContentProps: PropTypes.object,
	showBackButton: PropTypes.bool
};

export default React.memo(NewsCard);
