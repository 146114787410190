import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '../../styles';
import CheckBoxIcon from '../../mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '../../mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '../../mui/icons-material/Close';
import InputAdornment from '../../mui/material/InputAdornment';
import TextField from '../../mui/material/TextField';
import MenuItem from '../../mui/material/MenuItem';
import IconButton from '../../mui/material/IconButton';

const useStyles = createStyles((theme) => ({
	root: {},
	endAdornment: {
		marginRight: theme.spacing(2.5)
	},
	icon: {
		fontSize: '1.375rem',
		marginRight: theme.spacing()
	}
}), {
	name: 'RaMultiSelectField'
});

function MultiSelectField(props) {
	const {
		classes: classesProp,
		className,
		items = [],
		value = [],
		SelectPropsProp,
		onReset,
		...rest
	} = props;

	const { classes } = useStyles(props);

	const SelectProps = React.useMemo(() => ({
		multiple: true,
		renderValue: selected => selected.map(id => {
			const item = items.find(item => item?.id === id);
			return (item && item.title) || '';
		}).join(' / '),
		endAdornment: onReset && value.length > 0 && (
			<InputAdornment className={classes.endAdornment} position="end">
				<IconButton size="small" title="Auswahl zurücksetzen" onClick={onReset}>
					<CloseIcon fontSize="small"/>
				</IconButton>
			</InputAdornment>
		),
		...SelectPropsProp
	}), [items, value, onReset, SelectPropsProp, classes]);

	return (
		<TextField
			select
			{...rest}
			value={Array.isArray(value) ? value : []}
			SelectProps={SelectProps}
			className={classes.root}
		>
			{Array.isArray(items) && items.map(item => (
				<MenuItem key={item.id} value={item.id}>
					{value.includes(item.id) ? (
						<CheckBoxIcon className={classes.icon}/>
					) : (
						<CheckBoxOutlineBlankIcon className={classes.icon}/>
					)}
					{item.title}
				</MenuItem>
			))}
		</TextField>
	);
}

MultiSelectField.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	items: PropTypes.array.isRequired,
	value: PropTypes.array.isRequired,
	SelectPropsProp: PropTypes.object,
	onReset: PropTypes.func
};

export default React.memo(MultiSelectField);
