import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles, clsx } from '../../styles';
import MuiButton from '../../mui/material/Button';
import { useThemeProps } from '@mui/material/styles';
import Link from '../Link';

const useStyles = createStyles((theme, { variant }) => ({
	root: {},
	sizeSmall: {},
	sizeMedium: {},
	sizeLarge: {},
	sizeHuge: {
		lineHeight: 1.5,
		...(variant === 'outlined' ? {
			padding: theme.spacing(1.375, 2.875)
		} : {
			padding: theme.spacing(1.5, 3)
		}),
		fontSize: theme.typography.pxToRem(20),
		borderRadius: theme.spacing(1)
	},
	shapeDefault: {},
	shapeRounded: {
		borderRadius: theme.spacing(3.5)
	},
	shapeSquared: {
		borderRadius: 0
	}
}), {
	name: 'RaButton',
	mergeClassName: false
});

const Button = React.forwardRef(function Button(props, ref) {
	const {
		classes: classesProp,
		className,
		children,
		size = 'medium',
		shape = 'default',
		LinkComponent = Link,
		...rest
	} = useThemeProps({ props, name: 'RaButton' });

	const {
		sizeHuge,
		shapeDefault,
		shapeRounded,
		shapeSquared,
		...restClasses
	} = classesProp || {};

	const { classes } = useStyles(props);

	return (
		<MuiButton
			{...(props.to || props.href ? {
				component: LinkComponent
			} : null)}
			size={size === 'huge' ? 'large' : size}
			{...rest}
			ref={ref}
			className={clsx(
				classes.root,
				size === 'small' && classes.sizeSmall,
				size === 'medium' && classes.sizeMedium,
				size === 'large' && classes.sizeLarge,
				size === 'huge' && classes.sizeHuge,
				shape === 'default' && classes.shapeDefault,
				shape === 'rounded' && classes.shapeRounded,
				shape === 'squared' && classes.shapeSquared,
				className
			)}
			classes={restClasses}
		>
			{children}
		</MuiButton>
	);
});

Button.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	shape: PropTypes.oneOf(['default', 'rounded', 'squared']),
	size: PropTypes.oneOf(['small', 'medium', 'large', 'huge'])
};

export default React.memo(Button);
